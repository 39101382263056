/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.menu-left::-webkit-scrollbar {
  display: none;
}
.menu-left {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
}
.menu-left {
  max-height: 93vh;
  overflow-y: scroll;
}

@media screen and (max-width: 365px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: none !important;
  }
}

@media screen and (min-width: 320px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 768px) {
  .desktop {
    display: inline !important;
    font-size: 0.8rem !important;
  }
  .mobile {
    display: none !important;
  }
}
@media screen and (min-width: 1400px) {
  .desktop {
    font-size: 1rem !important;
  }
  .mobile {
    display: none !important;
  }
}

@media screen and (min-width: 2500) {
  .desktop {
    font-size: 10rem !important;
  }
  .mobile {
    display: none !important;
  }
}
.leaflet-popup-content-wrapper {
  z-index: 5000;
}
.leaflet-popup-content {
  width: 15rem !important;
  border-collapse: separate;
  font-size: 0.8rem;
}
.filter-map {
  z-index: 1600;
  position: fixed;
  top: 0;
  right: 0;
  align-items: center;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  // top: 0;
  // background-color: white;
  // padding: 1rem;
  // right: 0;
}
.popup-view-detail-map{
  z-index: 1600;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  // top: 0;
  // background-color: white;
  // padding: 1rem;
  // right: 0;
}