.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* .no-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-left: 0.5rem;
}

.no-scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

.no-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
} */

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.chart-height {
  max-height: 30vh;
}

.right-bar-height-calc {
  max-height: calc(70vh - 22rem);
}

.animation-slide-in {
  position: fixed;
  right: 2rem;
  bottom: calc(-30vh + 6rem);
  animation-name: slide-in;
  animation-duration: 1s;
}

.animation-slide-out {
  position: fixed;
  right: 2rem;
  bottom: calc(-100vh + 6rem);
  animation-name: slide-out;
  animation-duration: 1s;
}

@keyframes slide-in {
  from {
    bottom: calc(-100vh + 6rem);
  }
  to {
    bottom: calc(-30vh + 6rem);
  }
}

@keyframes slide-out {
  from {
    bottom: calc(-30vh + 6rem);
  }
  to {
    bottom: calc(-100vh + 6rem);
  }
}

.iframe-container {
  position: relative;
  overflow: scroll;
  width: 100%;
  height: 100vh;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* input,
input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 3;
} */

.vl {
  border: 1px solid #EEEEEE;
  transform: rotate(90deg);
  width: 32px;
  height: 0px;
  left: 408px;
  top: 19px;
}

.logoBrand img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dataline-popup {
  display: none;
}

.dataline-info:hover + .dataline-popup {
  display: block;
}



.card-vaccine{
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px; */
}
.wrap-search{
  background-color: white;
  border-radius: 0.5rem;
}
#wrap-search{
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.row-data-search{
  padding: 1rem 0;
  cursor: pointer;
  width: 100%;

}
.row-data-search:hover{
  background-color: rgb(248, 248, 248);
}
#result-search{
  display: hidden;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-items: start;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
  width: 100%;
  position: absolute;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
}
.card-chart{
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
}
.progress-bar-percent{
  background-color: #E2E2EA;
  height: 0.8rem;
  border-radius: 0.5rem;
  width: 100%;
}
.fill-progress-bar-percent{
  background-color: #3dd598;
  height: 0.8rem;
  border-radius: 0.5rem;
}
.flex-container {
  display: flex;
  flex-direction: space-around;
}
@media (max-width: 990px) {
  .flex-container {
    flex-direction: column;
  }}